@use "../custom" as *;

.form-input-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .label {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0;
        color: #626762;
        font-family: cobane;
        font-size: 0.875rem;
        margin-bottom: 0.2rem;

        input {
            background-color: rgb(251, 251, 250);
            color: #1f261e;
            font-family: cobane;
            font-weight: 400;
            font-size: 1.1rem;
            background-color: #fff;
            height: 3.5rem;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            appearance: none;
            border-radius: 0.7rem;
            line-height: 1.5;
            padding: 0.375rem 0.75rem;

            @include media-breakpoint-down(lg) {
                height: 51px;
                font-size: 14px;
                padding: 0 14px;
                line-height: 18px;
            }
            
        }

        input:focus {
            outline: 1px solid #4fb518;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
    }

    .form-error {
        font-size: 14px;
        color: #B30000;
        align-self: flex-start;
    }

}

.form-error {
  font-size: 14px;
  color: #B30000;
  align-self: flex-start;
}


.country-form-input {
    height: 55px;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    padding-left: 10px;

    input {
        height: 55px;
        border: 1px solid #dee2e6;
        border-radius: 0 10px 10px 0;
        padding-left: 15px;
    }

    input:focus {
        border-color: #4fb518;
        outline: 0;
        box-shadow: 0 0 0 .25rem rgb(79, 181, 24, .25)
    }
}

.clipboard-input {
    border: none;
    outline: none;
    width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    cursor: crosshair;
}