@use "custom" as *;
@use "./components/mixins" as *;

.credit-request-stepper {
    display: grid;
    place-items: center;
    margin-bottom: 100px;


    .request-credit-form-container {
        background-color: #F9FAF9;
        border-radius: 30px;
        min-height: 10rem;
        max-width: 714px;
        display: grid;
        place-items: center;
        padding:  2rem;
        
        @include media-breakpoint-down(lg) {
            padding:  2rem;
        }
        
        @include media-breakpoint-down(md) {
            padding: 1rem;
        }

        .request-credit-form-parent-box {
            width: 100%;
            max-width: 368px;
            margin: 50px auto 100px;
        
    
            .request-credit-modal-header {
                color: #1F261E;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 24px;
            }
            
            .step-indicator-container {
                display: flex;
                justify-content: space-between;
                width: 100%;
                height: 4px;
                gap: 10.4px;
                margin-bottom: 20px;
            }
            
            .step-indicator-child {
                width: 100%;
                height: 4px;
            }
            
            .step-indicator-child-pending {
                border-top: 2px solid #E0E6DD;
            }
            
            .step-indicator-child-success {
                border-top: 2px solid #4FB518;
            }
            
            .request-credit-modal-titles {
                color: #1F261E;
                font-weight: 600;
                font-size: 32px;
                line-height: 36px;
                
                @include media-breakpoint-down(md) {
                    font-size: 22px;
                    line-height: 28px;
                }
            }
            
            .request-credit-modal-texts {
                color: #626762;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;

                @include media-breakpoint-down(md) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            
            
            .request-credit-modal-titles-center {
                color: #1F261E;
                font-weight: 600;
                font-size: 32px;
                line-height: 36px;
                text-align: center;
                margin: 24px 0;
            }
            
            .center {
                display: grid;
                place-items: center;
            }
        }
            
    }
        
}