@use "../../custom" as *;

.request-credit-modal-outer-box .modal-content {
    background-color: #F9FAF9;
    
    @include media-breakpoint-down(lg) {
        padding: 0rem 2rem;
    }
    
    @include media-breakpoint-down(md) {
        padding: 0rem 0.5rem;
    }

}

.request-credit-modal-header {
    color: #1F261E;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 24px;
}

.step-indicator-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 4px;
    gap: 10.4px;
    margin-bottom: 20px;
}

.step-indicator-child {
    width: 100%;
    height: 4px;
}

.step-indicator-child-pending {
    border-top: 2px solid #E0E6DD;
}

.step-indicator-child-success {
    border-top: 2px solid #4FB518;
}

.request-credit-modal-titles {
    color: #1F261E;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
}

.request-credit-modal-texts {
    color: #626762;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}


.request-credit-modal-titles-center {
    color: #1F261E;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    margin: 24px 0;
}

.center {
    display: grid;
    place-items: center;
}


/* Rectangle 1840 */

// position: absolute;
// width: 714px;
// height: 843px;
// left: 543px;
// top: 151px;

// background: #F9FAF9;
// border-radius: 29.1329px;