@use "custom" as *;
@use "./components/mixins" as *;

.intro-image-box {
  img {
    display: block;
    max-width: 100%;
    width: auto;
    height: auto;
    padding-top: 2.5rem;
    margin: 0 auto;
    min-height: 419px;

    @include media-breakpoint-up(lg) {
      min-height: 774px;
    }
  }
}

.partner-station-logo {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
}

.bg-dark-green {
  background-color: $dark-green;
}

.bg-light-green {
  background-color: $green-5;
}

.radius-20-40{
    border-radius: 20px;

    @include media-breakpoint-up(lg){
        border-radius: 40px;
    }
}

.why-fuelcredit-row {
  max-height: max-content;
}

.why-fuelcredit-card {
  background-color: $gray-100;
  border-radius: 2.5rem;
  height: 100%;

  h3 {
    color: $black;
    font-family: cobane;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  p {
    color: $dark-70;
    font-family: cobane;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
}

.why-fuelcredit-customer-image {
  display: block;
  width: auto;
  height: auto;
  margin: 0 auto;
  max-width: 75%;

  @include media-breakpoint-up(lg) {
    margin-top: -50%;
    max-width: 100%;
  }
}

.fuelcredit-plans-section {
  padding: 6rem 2rem;
  background: $gray-100;

  @include media-breakpoint-down(lg) {
    padding: 4rem 1rem;
  }
}

.fuelcredit-plan-title {
  justify-content: center;
  text-align: center;

  p {
    width: 60%;
    color: $dark-70;
    font-family: cobane;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 2.125rem;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 0.5rem;

    p {
      width: auto;
    }
  }
}

.fuelcredit-plan-card-row {
  max-height: max-content;
  margin-bottom: 2rem;
}

.fuelcredit-plan-card {
  background: $white;
  border: 1px solid $dark-10;
  // border-radius: 2.5rem;
  border-radius: 1.875rem;
  padding: 3.5rem 3rem 1.5rem;
  height: 100%;
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(lg) {
    border-radius: 2.5rem;
  }

  &--quick {
    color: $primary;
  }

  &--basic {
    color: $orange;
  }

  &--convenience {
    color: $blue;
  }
  
  h3 {
    padding-left: 0;
    color: $dark;
    font-family: cobane;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 26px;

    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
      line-height: 32px;
    }
  }

  ul {
    padding-left: 0;

    li {
      color: $dark-70;
      margin-bottom: 0.75rem;
      font-family: cobane;
      font-weight: 400;
      font-size: .875rem;
      line-height: 22px;

      @include media-breakpoint-up(md) {
        font-size: 1.125rem;
        line-height: 28px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 2.5rem 1.5rem 1.5rem;
    height: auto;
    margin: 1rem auto;
  }
}

.trusted-by-section {
  margin: 10rem 2rem ;

  @include media-breakpoint-down(lg) {
    margin: 5rem 1rem;
  }

  @include media-breakpoint-down(md) {
    margin: 3rem 1rem;
  }
}

.trusted-by-row {
  margin-top: 4.5rem;

  @include media-breakpoint-down(lg) {
    margin-top: 3rem;
  }

  @include media-breakpoint-down(md) {
    margin-top: 2rem;
  }

  div {
    padding: 3rem;

    @include media-breakpoint-down(lg) {
      padding: 1rem 0.5rem;
    }

    ul {
      padding-left: 0;
      li {
        font-family: cobane;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.75rem;
      }
    }

    h3 {
      font-family: cobane;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 28px;
      margin-bottom: 1.5rem;

      @include media-breakpoint-up(lg) {
        font-size: 2rem;
        line-height: 42px;
      }

      .trusted-by-individuals {
        color: $primary;
      }
      .trusted-by-corporates {
        color: $blue;
      }
      .trusted-by-merchants {
        color: $orange;
      }
    }

    h4 {
      font-family: cobane;
      font-weight: 600;
      font-size: 1rem;
      line-height: 28px;
      // margin-top: 1.875rem;

      @include media-breakpoint-up(md) {
        font-size: 1.125rem;
        line-height: 28px;
      }
    }

    p {
      color: $dark-70;
      font-family: cobane;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 24px;

      @include media-breakpoint-up(lg) {
        font-size: 1.125rem;
        line-height: 28px;
      }
    }

    img {
      display: block;
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }
}

.needs-column-reverse {
  @include media-breakpoint-down(lg) {
    display: flex !important;
    flex-direction: column-reverse;
  }
}


.download-on-container {
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 35px;

  .playstore {
    width: 178px;
    height: 60px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid rgba(49, 88, 28, 1);

    a {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 10px;

      img {
        width: 25px;
        height: 27px;
      }

      span {
        font-size: 18px;
        color: rgba(31, 38, 30, 1);
      }

      @include media-breakpoint-down(md) {
        gap: 6px;
      }
    }
  }

  .appstore {
    width: 178px;
    height: 60px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid rgba(49, 88, 28, 1);

    a {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 10px;

      img {
        width: 22px;
        height: 27px;
      }

      span {
        font-size: 18px;
        color: rgba(31, 38, 30, 1);
      }

      @include media-breakpoint-down(md) {
        gap: 6px;
      }

    }
  }

  @include media-breakpoint-down(md) {
    display: flex;
    gap: 10px;
  }

}


.tech-partners {
  display:  grid;
  grid-template-columns: auto auto auto auto auto;
  row-gap: 40px;
  margin-top: 10px;
  padding-top: 30px;
  justify-content: space-between;
  align-items: center;

  .tech-partners-logo {
    align-self: center;
  }

  @media screen and (max-width: 769px) {
    grid-template-columns: auto auto auto;
    margin-top: 20px;
  }

  @media screen and (max-width: 577px) {
    grid-template-columns: auto auto;
    row-gap: 30px;
  }
}

.faq-accordion-title {
  font-family: cobane;
  font-weight: 500;
  color: $dark;
  font-size: 1rem;
  line-height: 20px;

  @include media-breakpoint-up(md){
    font-size: 1.5rem;
    line-height: 30px;
  }
}


.partner-stations {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.partner-station-logo {
  border: 1px solid #C7C9C7;
  padding: 35px;
  height: 151px;
}

.partner-station-logo:nth-of-type(-n+5) {
  border-top: none;
}

.partner-station-logo:nth-child(6), .partner-station-logo:nth-child(7), .partner-station-logo:nth-child(8), .partner-station-logo:nth-child(9), .partner-station-logo:nth-child(10) {
  border-bottom: none;
}

.partner-station-logo:nth-child(1), .partner-station-logo:nth-child(6) {
  border-left: none;
}

.partner-station-logo:nth-of-type(5), .partner-station-logo:nth-of-type(10) {
  border-right: none;
}

.partner-station-logo img {
  width: 80%;
  height: auto;
}

.partner-station-logo:first-child img {
  width: 60%;
}

@media screen and (max-width: 768px) {
  .partner-stations {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .partner-station-logo {
    border: none;
    border: 1px solid #C7C9C7;
    padding: 10px;
    height: 100px;
  }

  .partner-station-logo:nth-child(2), .partner-station-logo:nth-child(4), .partner-station-logo:nth-child(6), .partner-station-logo:nth-child(8), .partner-station-logo:nth-child(10) {
    border-right: none;
  }

  .partner-station-logo:nth-child(2), .partner-station-logo:nth-child(4), .partner-station-logo:nth-child(8), .partner-station-logo:nth-child(10) {
    border-left: none;
  }

  .partner-station-logo:nth-child(5) {
    border-right: 1px solid #C7C9C7;
    border-bottom: none;
  }
  .partner-station-logo:nth-child(6) {
    border-top: none;
  }

  .partner-station-logo:nth-child(1), .partner-station-logo:nth-child(3), .partner-station-logo:nth-child(5), .partner-station-logo:nth-child(7), .partner-station-logo:nth-child(9) {
    border-left: none;
  }

  .partner-station-logo img {
    width: 50%;
    height: auto;
    max-height: 60px;
  }
  
  .partner-station-logo:first-child img {
    width: 30%;
  }
}

// @media screen and (max-width: 425px) {
//   .partner-stations {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//   }

//   .partner-station-logo {
//     max-width: 100px; /* Adjust size for mobile */
//     min-width: 60px;
//   }
// }

