@use "../custom" as *;


.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f5f5f5;
  text-align: center;
  z-index: 1000;
}

.marquee-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  background-color: #f5f5f5;
  height: 92px;
  background-color: rgba(49, 88, 28, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.marquee-content {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 100s linear infinite;
}

.marquee-item {
  display: inline-block;
  margin-right: 50px;
  font-size: 16px;
}

.station-name {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  margin: 0 5px 0 15px;
}

.station-address {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.span-bold {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  margin-left: 5px;
}


/* Keyframes for the scrolling effect */
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

